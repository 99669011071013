import React, { useState, useEffect } from "react";
import Landscape from "../Components/Landscape";
import Portrait from "../Components/Portrait";
import { useSelector } from "react-redux";

const Inspection = () => {
  // const [dimensions, setDimensions] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // });
  // const updateDimensions = () => {
  //   setDimensions({...dimensions, width: window.innerWidth, height: window.innerHeight });
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", updateDimensions);
  //   return () => window.removeEventListener("resize", updateDimensions);
  // }, []);
  const isClaim = useSelector((state) => state.isClaim);

  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    [orientation, setOrientation] = useState(
      isLandscape() ? "landscape" : "portrait"
    ),
    onWindowResize = () => {
      clearTimeout(window.resizeLag);
      window.resizeLag = setTimeout(() => {
        delete window.resizeLag;
        setOrientation(isLandscape() ? "landscape" : "portrait");
      }, 20);
    };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    []
  );

  useEffect(() => {
    if (isClaim) {
      // console.log(isClaim);
      document.title = "Claim Inspection";
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          "content",
          "Web based Vehicle Inspection for Claims"
        );
      }
    } else {
      document.title = "Breakin Inspection";
      const metaDescriptionTag = document.querySelector(
        'meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute(
          "content",
          "Web based Vehicle Inspection for Breakin"
        );
      }
    }
  }, []);

  return <>{orientation === "portrait" ? <Landscape /> : <Portrait />}</>;
};

export default Inspection;
