import React, { useEffect } from 'react';
import Logo from '../Assets/Images/logo_hdfc.png'

const UploadError = () => {
    
    return (
        <section className="success_container">
        <img src={Logo} alt="hdfc-ergo" />
        <h1>Oops, looks like something went wrong from our side. :(</h1>
        <br />
        <h2 style={{ textAlign: 'left' }}>In case the issue persists, please try:</h2>
        <p style={{ textAlign: 'left' }}>
            <ul style={{ listStyleType: 'disc', paddingLeft: '20px', textAlign: 'left' }}>
            <li>Clicking on the link again to restart the process.</li>
            <br />
            <li>Restart your browser after closing all tabs entirely.</li>
            <br />
            <li>Make sure you have a stable internet connection.</li>
            </ul>
        </p>
        </section>
    );
};

export default UploadError;