import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const LeftContent = () => {
  const dispatch = useDispatch();

  const angleRef = useRef(null);

  const theme = useSelector((state) => state.theme);
  var car = useSelector((state) => state.car);


  // const handleChooseAngles = (angle) => {
  //   const copyData = JSON.parse(JSON.stringify(car.data));
  //   const tempData =
  //     copyData &&
  //     copyData.length &&
  //     copyData.map((e) => ({
  //       ...e,
  //       isActive: e.key === angle.key ? true : false,
  //       isupload: false,
  //     }));
  //   dispatch(setCars(tempData));
  //   dispatch(carCount(angle.key));
  // };

  const scrollToBottom = () => {
    angleRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (angleRef.current) {
      scrollToBottom();
    }  
  }, [angleRef, car.count]);


  return (
    <div
      className="side_menu"
      style={{ backgroundColor: theme === "dark" ? "#242424" : "#ffffff" }}
    >
      {car.data && car.data.length
        ? car.data.map((e) => (
            <div
              ref={e.key === car.count ? angleRef : null}
              className={`choose_angles ${e.isUpload ? "positionImg" : ""} `}
              // onClick={(j) => {
              //   handleChooseAngles(e);
              // }}
              style={{
                backgroundImage: e.isUpload && `url(${e.isUploadImg})`,
                backgroundColor: e.isActive ? "#EC6466" : "transparent",
              }}
              key={e.id}
            >
              {!e.isUpload && (
                <img
                  id={e.imgId}
                  className="sidebar_img"
                  src={e.ImageSrc}
                  alt={e.imgId}
                />
              )}
              <span
                className={`${e.isUpload ? "position_text" : ""} text_color `}
                style={{ color: theme === "dark" ? "#ffffff" : "#242424" }}
              >
                {e.name}
              </span>
            </div>
          ))
        : null}
    </div>
  );
};

export default LeftContent;
