import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTnC } from "../Redux/Features/tnCSlice";

const TermsCondition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(updateTnC());
    navigate("/");
  };
  return (
    <div className="tnCContainer">
      <h3 className="heading">Terms & Condition</h3>
      <p>
        {" "}
        I understand and agree that the Self Inspection application is a
        mobile-based solution provided by HDFC ERGO for the Customer who intends
        to avail of the facility of Self Inspection.
      </p>
      <p>
        The information provided by me, and the service availed through the
        Application has been provided on my free will and without any charge,
        and I understand that any unauthorized disclosure of information or
        commercial use of this application will attract legal action and
        indemnifying HDFC ERGO for any loss or damage or claim.
      </p>

      <p>
        I acknowledge that in accordance with the law being in force, multiple
        insurance policies cannot be issued to cover one vehicle. Hence, I shall
        ensure that at all times my vehicle is covered under only one insurance
        policy.
      </p>
      <p>
        I confirm that all information, data, documents and photographs
        (Information), provided by me to HDFC ERGO through the use of the
        services under the Application is authentic, accurate and without any
        mis-statements and concealment of facts. | further understand that HDFC
        ERGO holds the final discretion to issue a policy to me based on the
        Information provided by me through the Application. Hence, in case of
        any information or any portion thereof is found to be incorrect, wrong
        or there has been concealment of facts in any manner, HDFC ERGO shall
        reject any and all claims made by me under the Policy in respect of
        which the Information was given to HDFC ERGO through the Application and
        the insurance policy shall stand null and void from inception without
        refund of the premium already paid.
      </p>
      <button onClick={handleClick}>Agree & Continue</button>
    </div>
  );
};

export default TermsCondition;
