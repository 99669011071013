import React from "react";

const Modal = ({children }) => {
  return (
    <div className="modalBackground">
      
        {/* <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
          <img src={Close} alt='X' />
          </button>
        </div> */}
        {children}
    
    </div>
  );
};

export default Modal;
