/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import { damage } from '../Redux/Features/damageSlice';
import { Alert , AlertTitle, Stack, Modal, Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText }from '@mui/material';

const DamageSelection = () => {
const navigate = useNavigate();
const dispatch = useDispatch();
const [classMap, setClassMap] = useState({
zb1022: 0,
zb1030: 0,
zb1032: 0,
zb1031: 0,
zb1020: 0,
zb1025: 0,
zb1027: 0,
zb1028: 0,
zb1021: 0,
zb1026: 0,
zb1023: 0,
zb1029: 0,
zb1024: 0,
zb1016: 0,
zb1017: 0,
zb1003: 0,
zb1005: 0,
zb1004: 0,
zb1014: 0,
zb1015: 0,
zb1012: 0,
zb1013: 0,
zb1002: 0,
zb1019: 0,
zb1018: 0,
zb1011: 0,
zb1008A: 0,
zb1009A: 0,
zb1010: 0,
zb1007A: 0,
zb1001A: 0,
zb1006A: 0,
});


const [stateDamageData, setStateDamageData] = useState({});
const isDamageRecorded = useSelector((state) => state.damage.isDamageRecorded);
const [storageValue, setStorageValue] = useState(localStorage.getItem('lc_damage'));
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [showWarningModal, setShowWarningModal] = useState(false);
const [open, setOpen] = useState(false);

const breakin = useSelector((state) => state.breakin);

const classNames = [
"intensity01",
"intensity02",
"intensity03",
"intensity04",
"intensity05",
];

const changeColor = (id) => {
if (!isDamageRecorded) {
    const currentClassIndex = classMap[id];
    const nextClassIndex = (currentClassIndex + 1) % classNames.length;
    setClassMap({ ...classMap, [id]: nextClassIndex });
}
};

const encodeData = (data) => {
const text =
    "7E1sXThddD3DprfTJkbNY+szSTyPHOimYamZ6BSrmD45454545+s98bx3HSbVpCaZPVtlvNfj2GFI1oO6K9RK70KKhjRqf0oe";

const md5Hash = CryptoJS.MD5(text);
const key = CryptoJS.enc.Hex.parse(md5Hash.toString().slice(0, 32));

const encrypted = CryptoJS.TripleDES.encrypt(data, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
}).toString();

// console.log(encrypted);
return encrypted;
};

function convertJSON(json) {
const result = [];

for (const key in json) {
    const partCode = key.replace("zb", "");
    const intensity = json[key];
    result.push({ PartCode: partCode, Intensity: intensity });
}

// console.log(result);
return result;
}

useEffect(() => {
    const checkLocalStorage = () => {
    const updatedValue = localStorage.getItem('lc_damage');
    // console.log(updatedValue);
    if(updatedValue !== storageValue){
        setStorageValue(updatedValue);
    }
    };
    const interval = setInterval(checkLocalStorage, 1000);
    return () => clearInterval(interval);
},[storageValue]);

const saveStateAsJSON = () => {
    if (Object.keys(stateDamageData).length > 0) {
        // console.log("Damage Data State:", stateDamageData);
        // console.log(isDamageRecorded);
        const claimNo = breakin.claim_number;
        // console.log("Claim No:", claimNo);

        const encClaimNo = encodeData(claimNo);
        // console.log("Encrypted claimNo:", encClaimNo);

        const damageDataString = JSON.stringify(convertJSON(stateDamageData));
        // console.log("Converted JSON:", damageDataString);

        var localStorageKeyForDamageData = "lc_damage";

        if (damageDataString.length > 0) {
            var localStorageData = JSON.parse(damageDataString);
        } else {
            var localStorageData = "No Damage Selected";
        }

        window.localStorage.setItem(localStorageKeyForDamageData, localStorageData);

        dispatch(damage.setDamageData({ stateDamageData }));
        // console.log(damageDataString);

        const encDamageData = encodeData(damageDataString);
        // console.log("Encrypted damageData:", encDamageData);

        // Send the encrypted data to the backend API.
        saveDamageToDB(encClaimNo, encDamageData);
    } else {
        // Open warning dialog if no damage data is selected
        setShowWarningModal(true);
    }
};


async function saveDamageToDB(encClaimNo, encDamageData) {
try {
    const data = JSON.stringify({
    ClaimNo: encClaimNo,
    carDamagedData: encDamageData,
    });

    const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${process.env.REACT_APP_API_URL}/savePOIDamage`,
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
        "Content-Type": "application/json",
    },
    data: data,
    };

    const response = await axios.post(config.url, config.data, {
    headers: config.headers,
    });
    // console.log(JSON.stringify(response.data));

    // If the request is successful, setDamageAlreadyRecorded to true
    dispatch(damage.setDamageRecorded(true));
    // window.alert("Damage Successfully Saved.\n Press return to Claim Form to close this window.")
    // setShowSuccessAlert(true);
    setShowSuccessModal(true);
    
} catch (error) {
    // console.log(error);
}
}
const handleCloseModal = () => {
    setShowSuccessModal(false);
    setShowWarningModal(false);
};

// useEffect(() => {
//     if (showSuccessModal || showWarningModal) {
//         const timer = setTimeout(() => {
//             handleCloseModal();
//         }, 4000);
//         return () => clearTimeout(timer);
//     }
// }, [showSuccessModal, showWarningModal]);

async function fetchDamage() {
try {
    const damageData = await fetchDamageFromDB();
// console.log("This is Damage data", damageData);
// setStateDamageData(damageData);
if (Object.keys(damageData).length > 0) {
    // console.log(damageData);
    dispatch(damage.setDamageRecorded(true));
    // console.log(isDamageRecorded);
}

// Allocate a value of 1 to the rest of the elements in classMap
const updatedDamageData = { ...classMap }; // Start with a copy of classMap
for (const key in classMap) {
    const intensity = parseInt(damageData[key]) - 1 || 0; // Subtract 1 and default to 1 if intensity is -1
    updatedDamageData[key] = intensity >= 0 ? intensity : 0; // Ensure intensity is at least 0
}
// console.log(updatedDamageData);

const updatedDamageDataWithoutZeros = Object.fromEntries(
    Object.entries(updatedDamageData).filter(([key, value]) => value !== 0)
);

// console.log(updatedDamageDataWithoutZeros);
setStateDamageData(updatedDamageDataWithoutZeros);

    
    

    // Update state based on the condition
    // setDamageRecorded(isDamageRecorded);
    // dispatch(damage.setDamageRecorded(isDamageRecorded));
    
    if (isDamageRecorded) {
    // Set the state with updatedDamageData
    setClassMap(updatedDamageData);

    var localStorageKeyForDamageData="lc_damage";

    var localStorageData = JSON.stringify(updatedDamageDataWithoutZeros);

    window.localStorage.setItem(localStorageKeyForDamageData,localStorageData)

    dispatch(damage.setDamageData({stateDamageData}));
    // window.alert("Damage already selected. Press return to Claim Form to close this window.")


    }
} catch (error) {
    console.error(error);
}
}

// async function fetchDamageFromDB() {
// try {
//     const encClaimNo = encodeData(breakin.claim_number);

//     let data = JSON.stringify({
//     ClaimNo: encClaimNo,
//     });

//     const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/fetchPOIDamage`,
//     data,
//     {
//         headers: {
//         "Content-Type": "application/json",
//         },
//     }
//     );

//     const responseData =
//     response.data?.GetDataForPOICustomerResult?.["Data For Customer POI"] ||
//     [];

//     const damageData = responseData.reduce((acc, entry) => {
//     const partCode = `zb${entry["Part Code"]}`;
//     const intensity = `intensity${entry.Intensity.padStart(2, "0")}`;
//     acc[partCode] = intensity;
//     return acc;
//     }, {});

//     console.log(damageData);
//     return damageData;
// } catch (error) {
//     console.error(error);
//     return {};
// }
// }

async function fetchDamageFromDB() {
    try {
        const encClaimNo = encodeData(breakin.claim_number);

        let data = JSON.stringify({
            ClaimNo: encClaimNo,
        });

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/fetchPOIDamage`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
                    "Content-Type": "application/json",
                },
            }
        );

        // Check if the response contains an error message
        if (
            response.data?.GetDataForPOICustomerResult &&
            response.data.GetDataForPOICustomerResult.Message &&
            response.data.GetDataForPOICustomerResult.Message.Error
        ) {
            return null;
        }

        // Directly return the response data
        return response.data;
    } catch (error) {
        console.error(error);
        return null; // Return null in case of error
    }
}

const handleClose = () => {
    setOpen(false);
};

const handleGoBack = () => {
    window.close();
};

useEffect(() => {
    document.title = "Damage Selection";
fetchDamage();
}, [isDamageRecorded]);

useEffect(() => {
// Convert classMap to JSON format with indices mapped
const stateJSON = {};
for (const key in classMap) {
    const className = classNames[classMap[key]];
    const index = classNames.indexOf(className); // Get index directly from classNames array
    // Increment the index by 1 if it's within the range of classNames array
    const incrementedIndex = index !== -1 ? index + 1 : index;
    // Map the index instead of class name, but exclude if index is 1
    if (incrementedIndex !== 1) {
    stateJSON[key] = incrementedIndex;
    }
}
// console.log("State JSON:", JSON.stringify(stateJSON));

// Update the damageData state
setStateDamageData(stateJSON);
}, [classMap]);

//////////////////////////////////////////////////////////

const btnBackClaimForm_Click = () => {
    if (!isDamageRecorded) {
        // setShowWarningModal(true);
        setOpen(true);
    } else {
        window.close();
    }
};


return (
<div class="mainarea">
    <div class="mainarea-sub">
    <div class="sub text-center" id="container">
        <div>
        <span
            id="spnMessage"
            style={{ color: "red", visibility: "hidden" }}
        ></span>
        </div>

        <svg
        version="1.1"
        width="100%"
        id="Svg1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 612 800"
        style={{ enableBackground: "new 0 0 612 792" }}
        xmlSpace="preserve"
        >
        <style>
            {`.st1 { display: none; }
            .st2 { fill: none; stroke: #B6BCBF; stroke-width: 1.0997; stroke-miterlimit: 10; }
            .st3 { fill: none; stroke: #B6BCBF; stroke-width: 1.0176; stroke-miterlimit: 10; }
            .st4 { fill: none; stroke: #B6BCBF; stroke-width: 1.1071; stroke-miterlimit: 10; }
            .st5 { fill: none; stroke: #B6BCBF; stroke-width: 1.0245; stroke-miterlimit: 10; }
            .st6 { fill: none; stroke: #000000; stroke-width: 1.5; stroke-miterlimit: 10; }
            .st8 { fill: none; stroke: #B6BCBF; stroke-width: 0.971; stroke-miterlimit: 10; }
            .st9 { fill: none; stroke: #B6BCBF; stroke-width: 0.8985; stroke-miterlimit: 10; }
            .st10 { fill: none; stroke: #B6BCBF; stroke-width: 0.9776; stroke-miterlimit: 10; }
            .st11 { fill: none; stroke: #B6BCBF; stroke-width: 0.9046; stroke-miterlimit: 10; }
            .st12 { fill: none; stroke: #000000; stroke-width: 1.3245; stroke-miterlimit: 10; }
            .st13 { fill: #7E7E7E; }
            .st14 { fill: #A2A2A2; }
            .st15 { fill: #9F9F9F; }
            .st17 { fill: #B2C3BE; }
            .intensity00 { fill: #ED1C24; }
            .intensity01 { fill: #9E9F9F; }
            .intensity02 { fill: #4dff4d; }
            .intensity03 { fill: #ffff33; }
            .intensity04 { fill: #ffa500; }
            .intensity05 { fill: #ff0000; }
            .button { padding: 15px 25px; font-size: 24px; text-align: center; cursor: pointer; outline: none; color: #fff; background-color: #04AA6D; border: none; border-radius: 15px; box-shadow: 0 6px #999; }
            .button:hover { background-color: #3e8e41 }
            .button:active { background-color: #3e8e41; box-shadow: 0 5px #666; transform: translateY(4px); }
            .intensity0 { fill: #7F7F7F; }
            .intensity00 { fill: #9E9F9F; }`}
        </style>
        <path
            className={classNames[classMap["zb1022"]]}
            id="zb1022"
            onClick={(event) => {
            changeColor(event.target.id);
            }}
            d="M176.1,86.3c0-0.3,0-0.5,0-0.7l13.7-2.8l-0.4-1.8l-13.8,2.8c-0.3-0.7-0.7-1.3-1.1-1.9l14.8-3.2l-0.4-1.9
l-16.1,3.5l0,0.2c-0.3-0.2-0.7-0.4-1-0.5l-0.1-14.8l-1.9,0l0.1,14.4c-0.1,0-0.3,0-0.4,0c-0.6,0-1.2,0.1-1.8,0.2l-0.3-14.1l-1.8,0
l0.3,14.5l-16.1-3.5l-0.4,1.9l14.9,3.3c-0.5,0.6-0.9,1.2-1.1,1.9l-14-2.9l-0.4,1.8l13.9,2.9c0,0.2,0,0.5,0,0.7
c0,1.3,0.4,2.6,1.1,3.6l-9.9,10.4l1.4,1.3l9.7-10.2c0.6,0.5,1.2,0.9,1.9,1.1l-9.5,10.3l1.3,1.2l10.1-10.9l-0.2-0.1
c0.2,0,0.4,0,0.7,0c0.1,0,0.3,0,0.4,0l-0.1,0.1l10.1,10.9l1.3-1.2l-9.5-10.2c0.7-0.3,1.4-0.6,1.9-1.1l9.6,10.1l1.4-1.3L175,90.1
C175.7,89,176.1,87.7,176.1,86.3z M165.9,80.6L165.9,80.6C165.9,80.7,165.9,80.7,165.9,80.6l0-0.2L165.9,80.6z M169.4,88.9
c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5C172,87.7,170.8,88.9,169.4,88.9z"
        />
        <path
            className={classNames[classMap["zb1030"]]}
            id="zb1030"
            onClick={(event) => {
            changeColor(event.target.id);
            }}
            d="M453.4,86.3c0-0.3,0-0.5,0-0.7l13.7-2.8l-0.4-1.8l-13.8,2.8c-0.3-0.7-0.7-1.3-1.1-1.9l14.8-3.2l-0.4-1.9
L450,80.4l0,0.2c-0.3-0.2-0.7-0.4-1-0.5l-0.1-14.8l-1.9,0l0.1,14.4c-0.1,0-0.3,0-0.4,0c-0.6,0-1.2,0.1-1.8,0.2l-0.3-14.1l-1.8,0
l0.3,14.5l-16.1-3.5l-0.4,1.9l14.9,3.3c-0.5,0.6-0.9,1.2-1.1,1.9l-14-2.9l-0.4,1.8l13.9,2.9c0,0.2,0,0.5,0,0.7
c0,1.3,0.4,2.6,1.1,3.6l-9.9,10.4l1.4,1.3l9.7-10.2c0.6,0.5,1.2,0.9,1.9,1.1l-9.5,10.3l1.3,1.2l10.1-10.9L446,93c0.2,0,0.4,0,0.7,0
c0.1,0,0.3,0,0.4,0l-0.1,0.1l10.1,10.9l1.3-1.2l-9.5-10.2c0.7-0.3,1.4-0.6,1.9-1.1l9.6,10.1l1.4-1.3l-9.7-10.2
C452.9,89,453.4,87.7,453.4,86.3z M443.2,80.6L443.2,80.6C443.2,80.7,443.1,80.7,443.2,80.6l0-0.2L443.2,80.6z M446.7,88.9
c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C449.2,87.7,448.1,88.9,446.7,88.9z"
        />
        <path
            className={classNames[classMap["zb1032"]]}
            id="zb1032"
            onClick={(event) => {
            changeColor(event.target.id);
            }}
            d="M476,630.4c0-0.2,0-0.4,0-0.7l12.1-2.5l-0.3-1.6l-12.2,2.5c-0.2-0.6-0.6-1.2-1-1.7l13-2.9l-0.4-1.7l-14.2,3.1
l0,0.2c-0.3-0.2-0.6-0.3-0.9-0.4l-0.1-13.1l-1.7,0l0.1,12.7c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1,0.1-1.5,0.2l-0.3-12.5l-1.6,0l0.3,12.8
l-14.2-3.1l-0.4,1.7l13.2,2.9c-0.4,0.5-0.8,1-1,1.7l-12.3-2.5l-0.3,1.6l12.3,2.5c0,0.2,0,0.4,0,0.6c0,1.2,0.4,2.3,0.9,3.2l-8.7,9.2
l1.2,1.2l8.6-9c0.5,0.4,1.1,0.8,1.7,1l-8.4,9.1l1.2,1.1l8.9-9.7l-0.1-0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0l-0.1,0.1l8.9,9.7
l1.2-1.1l-8.3-9c0.6-0.2,1.2-0.6,1.7-1l8.5,9l1.2-1.2l-8.6-9C475.6,632.8,476,631.7,476,630.4z M467,625.4L467,625.4
C467,625.4,467,625.4,467,625.4l0-0.2L467,625.4z M470.1,632.6c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2
C472.3,631.7,471.3,632.6,470.1,632.6z"
        />
        <path
            className={classNames[classMap["zb1031"]]}
            id="zb1031"
            onClick={(event) => {
            changeColor(event.target.id);
            }}
            d="M148.6,630.4c0-0.2,0-0.4,0-0.7l12.1-2.5l-0.3-1.6l-12.2,2.5c-0.2-0.6-0.6-1.2-1-1.7l13-2.9l-0.4-1.7l-14.2,3.1
l0,0.2c-0.3-0.2-0.6-0.3-0.9-0.4l-0.1-13.1l-1.7,0l0.1,12.7c-0.1,0-0.2,0-0.3,0c-0.5,0-1.1,0.1-1.5,0.2l-0.3-12.5l-1.6,0l0.3,12.8
l-14.2-3.1l-0.4,1.7l13.2,2.9c-0.4,0.5-0.8,1-1,1.7l-12.3-2.5l-0.3,1.6l12.3,2.5c0,0.2,0,0.4,0,0.6c0,1.2,0.4,2.3,0.9,3.2l-8.7,9.2
l1.2,1.2l8.6-9c0.5,0.4,1.1,0.8,1.7,1l-8.4,9.1l1.2,1.1l8.9-9.7l-0.1-0.1c0.2,0,0.4,0,0.6,0c0.1,0,0.3,0,0.4,0l-0.1,0.1l8.9,9.7
l1.2-1.1l-8.3-9c0.6-0.2,1.2-0.6,1.7-1l8.5,9l1.2-1.2l-8.6-9C148.2,632.8,148.6,631.7,148.6,630.4z M139.6,625.4L139.6,625.4
C139.6,625.4,139.6,625.4,139.6,625.4l0-0.2L139.6,625.4z M142.7,632.6c-1.2,0-2.2-1-2.2-2.2c0-1.2,1-2.2,2.2-2.2
c1.2,0,2.2,1,2.2,2.2C144.9,631.7,143.9,632.6,142.7,632.6z"
        />
        <g>
            <path
            d="M446.7,109.3c-12.7,0-23-10.3-23-23c0-12.7,10.3-23,23-23c12.7,0,23,10.3,23,23C469.7,99,459.3,109.3,446.7,109.3z
M446.7,66c-11.2,0-20.3,9.1-20.3,20.3s9.1,20.3,20.3,20.3c11.2,0,20.3-9.1,20.3-20.3S457.9,66,446.7,66z"
            />
        </g>
        <g>
            <path
            d="M169.4,109.3c-12.7,0-23-10.3-23-23c0-12.7,10.3-23,23-23c12.7,0,23,10.3,23,23C192.4,99,182.1,109.3,169.4,109.3z
M169.4,66c-11.2,0-20.3,9.1-20.3,20.3s9.1,20.3,20.3,20.3s20.3-9.1,20.3-20.3S180.7,66,169.4,66z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1020"]]}
            id="zb1020"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M165.4,133.9v-10.7h53.4l28,92.3c1.7,5.7-2.5,11.4-8.4,11.4h-71.7v-8.3c0,0,46.6-9.2,36.5-49
C203.2,169.6,198.4,142.2,165.4,133.9z"
            />
        </g>
        <g>
            <path
            id="zb1025"
            className={classNames[classMap["zb1025"]]}
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M168.7,161.1c8.1,0,14.7,6.6,14.7,14.7c0,8.1-6.6,14.7-14.7,14.7c-8.1,0-14.7-6.6-14.7-14.7
C154,167.6,160.6,161.1,168.7,161.1 M168.7,154.9c-11.5,0-20.8,9.3-20.8,20.8c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
C189.4,164.2,180.1,154.9,168.7,154.9L168.7,154.9z"
            />
        </g>
        <g>
            <path
            d="M142.7,650.7c-11.2,0-20.3-9.1-20.3-20.3c0-11.2,9.1-20.3,20.3-20.3c11.2,0,20.3,9.1,20.3,20.3
C163,641.6,153.9,650.7,142.7,650.7z M142.7,612.5c-9.9,0-18,8.1-18,18s8.1,18,18,18c9.9,0,18-8.1,18-18S152.6,612.5,142.7,612.5z"
            />
        </g>
        <g>
            <path
            id="zb1027"
            className={classNames[classMap["zb1027"]]}
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M143.9,572.4c8.1,0,14.7,6.6,14.7,14.7c0,8.1-6.6,14.7-14.7,14.7c-8.1,0-14.7-6.6-14.7-14.7
C129.2,579,135.8,572.4,143.9,572.4 M143.9,566.2c-11.5,0-20.8,9.3-20.8,20.8c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
C164.7,575.6,155.4,566.2,143.9,566.2L143.9,566.2z"
            />
        </g>
        <g>
            <path
            d="M470.1,650.7c-11.2,0-20.3-9.1-20.3-20.3c0-11.2,9.1-20.3,20.3-20.3s20.3,9.1,20.3,20.3
C490.4,641.6,481.3,650.7,470.1,650.7z M470.1,612.5c-9.9,0-18,8.1-18,18s8.1,18,18,18c9.9,0,18-8.1,18-18S480,612.5,470.1,612.5z"
            />
        </g>
        <g>
            <path
            id="zb1028"
            className={classNames[classMap["zb1028"]]}
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M471.3,572.4c8.1,0,14.7,6.6,14.7,14.7c0,8.1-6.6,14.7-14.7,14.7c-8.1,0-14.7-6.6-14.7-14.7
C456.6,579,463.2,572.4,471.3,572.4 M471.3,566.2c-11.5,0-20.8,9.3-20.8,20.8c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
C492.1,575.6,482.8,566.2,471.3,566.2L471.3,566.2z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1021"]]}
            id="zb1021"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M451.6,133.9v-10.7h-53.4l-28,92.3c-1.7,5.7,2.5,11.4,8.4,11.4h71.7v-8.3c0,0-46.6-9.2-36.5-49
C413.7,169.6,418.6,142.2,451.6,133.9z"
            />
        </g>
        <g>
            <path
            id="zb1026"
            className={classNames[classMap["zb1026"]]}
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M448.1,161.1c8.1,0,14.7,6.6,14.7,14.7c0,8.1-6.6,14.7-14.7,14.7s-14.7-6.6-14.7-14.7C433.4,167.6,440,161.1,448.1,161.1
M448.1,154.9c-11.5,0-20.8,9.3-20.8,20.8c0,11.5,9.3,20.8,20.8,20.8c11.5,0,20.8-9.3,20.8-20.8
C468.9,164.2,459.5,154.9,448.1,154.9L448.1,154.9z"
            />
        </g>
        <g>
            <polygon
            className={`st13 ${classNames[classMap["zb1023"]]}`}
            id="zb1023"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            points="337,121.7 359.2,144.2 359.2,121.7 	"
            />
            <polygon
            className={`st13 ${classNames[classMap["zb1029"]]}`}
            id="zb1029"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            points="256.5,144.2 278.7,121.7 256.5,121.7 	"
            />
            <rect
            x="281"
            y="134.4"
            class="st13"
            width="54.4"
            height="3.7"
            id="zb1024"
            className={classNames[classMap["zb1024"]]}
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            />
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1016"]]}
                id="zb1016"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M222.2,292.3l-55.6-0.2v102.4h87.1v-63C253.7,331.5,245.1,306.5,222.2,292.3z M213.4,385.5h-5.7v-20.8
c-0.2-0.2,5.7,0,5.7,0C222.2,375.6,213.4,385.5,213.4,385.5z M248.6,391.4H220V300c19.3,4.4,28.7,32.4,28.7,32.4V391.4z"
            />
            </g>
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1017"]]}
                id="zb1017"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M362.7,331.5v63h87.1V292.1l-55.6,0.2C371.3,306.5,362.7,331.5,362.7,331.5z M403,364.8c0,0,5.9-0.2,5.7,0
v20.8H403C403,385.5,394.2,375.6,403,364.8z M367.7,332.4c0,0,9.4-28,28.7-32.4v91.4h-28.7V332.4z"
            />
            </g>
        </g>
        <g>
            <path
            className={classNames[classMap["zb1003"]]}
            id="zb1003"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M259.1,317.7l13.9,26.7c0,0,31.2-8,69.9,0l14.2-26.3C357,318.1,311.3,293.3,259.1,317.7z"
            />
        </g>
        <g>
            <path
            className={`st14 ${classNames[classMap["zb1005"]]}`}
            id="zb1005"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M258.8,501.9l10.8-18.7c0,0,34.1,8,76.3,0l10.6,18.2C356.6,501.5,319.3,526.2,258.8,501.9z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1004"]]}
            id="zb1004"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M258.7,366.9c0,0,51.3-13.7,99.2,0c0,0-21.3,38.1,0,93.6c0,0-41.6,14-99.5,0
C258.4,460.5,283.4,419.8,258.7,366.9z"
            />
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1014"]]}
                id="zb1014"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M166.6,419.5V479c0,0,31.6,2,31.3,42h23.3c0,0,26-14.9,31.8-38.5v-63H166.6z M210.9,516.2h-4.7v-20.7h4.7
C218,507.3,210.9,516.2,210.9,516.2z M249.5,479.9c0,0-9.9,28.4-29.5,34.4v-92h29.5V479.9z"
            />
            </g>
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1015"]]}
                id="zb1015"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M362.7,419.5v63c5.9,23.6,32.1,38.5,32.1,38.5h23.6c-0.3-40,31.6-42,31.6-42v-59.5H362.7z M405.3,495.5h4.7
v20.7h-4.7C405.3,516.2,398,507.3,405.3,495.5z M366.2,422.3H396v92c-19.7-6-29.7-34.4-29.7-34.4V422.3z"
            />
            </g>
        </g>
        <g>
            <path
            className={classNames[classMap["zb1012"]]}
            id="zb1012"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M133.1,509.9L133.1,509.9V303.4l0,0c7.6,0,13.7,6.1,13.7,13.7v179.1C146.8,503.8,140.6,509.9,133.1,509.9z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1013"]]}
            id="zb1013"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M480.7,509.9L480.7,509.9V303.4l0,0c-7.6,0-13.7,6.1-13.7,13.7v179.1C467,503.8,473.2,509.9,480.7,509.9z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1002"]]}
            id="zb1002"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M340.4,264.6c0,0-21.1-7.9-32.8-8.2c-11.7,0.3-32.8,8.2-32.8,8.2c-11.7,3.2-10.2-9-10.2-9l8.2-49.3
c4.6-23.2,31.3-27.3,34.7-27.7c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0c3.4,0.4,30,4.5,34.7,27.7l8.2,49.3
C350.6,255.6,352.1,267.8,340.4,264.6z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1002"]]}
            d="M307.8,178.5L307.8,178.5C307.8,178.6,307.8,178.6,307.8,178.5C307.8,178.5,307.8,178.5,307.8,178.5z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1002"]]}
            d="M307.6,178.6c-0.1,0-0.1,0-0.2,0v0C307.5,178.5,307.5,178.5,307.6,178.6z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1019"]]}
            id="zb1019"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M413,241.5L413,241.5c2.9,3.5,2.4,8.8-1.1,11.7l-42,34.5c-1.8,1.5-4.4,1.2-5.9-0.6l0,0
c-1.5-1.8-1.2-4.4,0.6-5.9L413,241.5z"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1018"]]}
            id="zb1018"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M205.3,241.5L205.3,241.5c-2.9,3.5-2.4,8.8,1.1,11.7l42,34.5c1.8,1.5,4.4,1.2,5.9-0.6l0,0
c1.5-1.8,1.2-4.4-0.6-5.9L205.3,241.5z"
            />
        </g>
        <g>
            <path
            className={`st15 ${classNames[classMap["zb1011"]]}`}
            id="zb1011"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M471.4,556.8c0,0-25.6,13.6-34.8,33l-0.7,77.2l-37-7V615l-37.2-60c0,0-7.9-19.2,3.1-12l32.4,28.7
c0,0,11.8,6.3,23.8,0.9C421.1,572.5,471.2,538.4,471.4,556.8z"
            />
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1006A"]]}
                id="zb1006B"
                onClick={(event) => {
                changeColor("zb1006A");
                }}
                d="M257.6,578.5v22.1c0,5.7,4.6,10.3,10.3,10.3h81.2c5.7,0,10.3-4.6,10.3-10.3v-22.1H257.6z M328.7,588.9
c0,8.3-6.7,15-15,15h-13.8c-8.3,0-15-6.7-15-15v-0.8h43.8V588.9z"
            />
            </g>
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1008A"]]}
                id="zb1008A"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M296.2,650.8h-33.4c-3.1,0-5.7-2.5-5.7-5.7v-10.4c0-3.1,2.5-5.7,5.7-5.7h33.4c3.1,0,5.7,2.5,5.7,5.7v10.4
C355.4,648.3,352.8,650.8,296.2,650.8z"
            />
            </g>
            <g>
            <circle
                id="zb1008B"
                fill="#ff8080"
                cx="270"
                cy="640.2"
                r="8.8"
            />
            </g>
            <g>
            <circle
                id="zb1008C"
                fill="#ff8080"
                cx="290.4"
                cy="640.2"
                r="8.8"
            />
            </g>
        </g>
        <g>
            <path
            className={classNames[classMap["zb1009A"]]}
            id="zb1009A"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M349.7,650.8h-33.4c-3.1,0-5.7-2.5-5.7-5.7v-10.4c0-3.1,2.5-5.7,5.7-5.7h33.4c3.1,0,5.7,2.5,5.7,5.7v10.4
C355.4,648.3,352.8,650.8,349.7,650.8z"
            />
        </g>
        <g>
            <circle id="zb1009B" fill="#ff8080" cx="323" cy="640.2" r="8.8" />
        </g>
        <g>
            <circle
            id="zb1009C"
            fill="#ff8080"
            cx="343.4"
            cy="640.2"
            r="8.8"
            />
        </g>
        <g>
            <path
            className={classNames[classMap["zb1010"]]}
            id="zb1010"
            onClick={(event) => {
                changeColor(event.target.id);
            }}
            d="M144.5,556.8c0,0,25.6,13.6,34.8,33l0.7,77.2l37-7V615l37.2-60c0,0,7.9-19.2-3.1-12l-32.4,28.7
c0,0-11.8,6.3-23.8,0.9C194.8,572.5,144.7,538.4,144.5,556.8z"
            />
        </g>
        <g>
            <g>
            <path
                className={classNames[classMap["zb1007A"]]}
                id="zb1007A"
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                d="M329.4,689.5h-42.7c-2.7,0-4.9,2.2-4.9,4.9v0c0,2.7,2.2,4.9,4.9,4.9h42.7c2.7,0,4.9-2.2,4.9-4.9v0
C334.3,691.7,332.1,689.5,329.4,689.5z"
            />
            </g>
            <g>
            <g>
                <path
                className={classNames[classMap["zb1007A"]]}
                id="zb1007B"
                onClick={(event) => {
                    changeColor("zb1007A");
                }}
                d="M274.8,695v-1.1c0-1.8-1.3-3.3-3-3.6c-4.3-0.8-7.7-3.4-8.8-7.3c-0.5-1.8-2-3-3.9-3l0,0
    c-2.5,0-4.4,2.3-3.9,4.7c1.6,7.8,7.1,13.9,15.9,14.1C273,698.7,274.8,697.1,274.8,695z"
                />
            </g>
            </g>
            <g>
            <g>
                <path
                className={classNames[classMap["zb1007A"]]}
                id="zb1007C"
                onClick={(event) => {
                    changeColor("zb1007A");
                }}
                d="M341.3,695v-1.1c0-1.8,1.3-3.3,3-3.6c4.3-0.8,7.7-3.4,8.8-7.3c0.5-1.8,2-3,3.9-3l0,0c2.5,0,4.4,2.3,3.9,4.7
    c-1.6,7.8-7.1,13.9-15.9,14.1C343,698.7,341.3,697.1,341.3,695z"
                />
            </g>
            </g>
        </g>
        <g>
            <g>
            <path
                id="zb1001A"
                className={classNames[classMap["zb1001A"]]}
                onClick={(event) => {
                changeColor(event.target.id);
                }}
                class="intensity0"
                d="M329.4,88.5h-42.7c-2.7,0-4.9-2.2-4.9-4.9v0c0-2.7,2.2-4.9,4.9-4.9h42.7c2.7,0,4.9,2.2,4.9,4.9v0
C334.3,86.3,332.1,88.5,329.4,88.5z"
            />
            </g>
            <g>
            <g>
                <path
                id="zb1001B"
                className={classNames[classMap["zb1001A"]]}
                onClick={(event) => {
                    changeColor("zb1001A");
                }}
                class="intensity0"
                d="M274.8,83.1v1.1c0,1.8-1.3,3.3-3,3.6c-4.3,0.8-7.7,3.4-8.8,7.3c-0.5,1.8-2,3-3.9,3l0,0
    c-2.5,0-4.4-2.3-3.9-4.7c1.6-7.8,7.1-13.9,15.9-14.1C273,79.3,274.8,81,274.8,83.1z"
                />
            </g>
            </g>
            <g>
            <g>
                <path
                id="zb1001C"
                className={classNames[classMap["zb1001A"]]}
                onClick={(event) => {
                    changeColor("zb1001A");
                }}
                class="intensity0"
                d="M341.3,83.1v1.1c0,1.8,1.3,3.3,3,3.6c4.3,0.8,7.7,3.4,8.8,7.3c0.5,1.8,2,3,3.9,3l0,0c2.5,0,4.4-2.3,3.9-4.7
    c-1.6-7.8-7.1-13.9-15.9-14.1C343,79.3,341.3,81,341.3,83.1z"
                />
            </g>
            </g>
        </g>
        <path
            className={classNames[classMap["zb1006A"]]}
            id="zb1006A"
            onClick={(event) => {
            changeColor(event.target.id);
            }}
            d="M342.4,541.1h-33.9H308h-34.3c-12.8-5.7-16.8,3.3-16.8,3.3l0,27.1H308h0.5h50.6v-27.1
C359.1,544.4,355.1,535.4,342.4,541.1z"
        />

        <text id="txtIntensity1" x={50} y={720} dy=".35em">
            No Damage
        </text>
        <rect
            id="DenoteIntensity1"
            x={150}
            y={710}
            width={25}
            height={25}
            style={{ fill: "#9E9F9F" }}
        />
        <text id="txtIntensity2" x={370} y={720} dy=".35em">
            Scratch-No Dent
        </text>
        <rect
            id="DenoteIntensity2"
            x={500}
            y={710}
            width={25}
            height={25}
            style={{ fill: "#4dff4d" }}
        />
        <text id="txtIntensity3" x={50} y={750} dy=".35em">
            Light Dent
        </text>
        <rect
            id="DenoteIntensity3"
            x={150}
            y={740}
            width={25}
            height={25}
            style={{ fill: "#ffff33" }}
        />
        <text id="txtIntensity4" x={400} y={750} dy=".35em">
            Torn | Crack
        </text>
        <rect
            id="DenoteIntensity4"
            x={500}
            y={740}
            width={25}
            height={25}
            style={{ fill: "#ff0000" }}
        />
        <text id="txtIntensity5" x={50} y={780} dy=".35em">
            Heavy Dent
        </text>
        <rect
            id="DenoteIntensity5"
            x={150}
            y={770}
            width={25}
            height={25}
            style={{ fill: "#ffa500" }}
        />
        </svg>
    </div>

    <html xmlns="http://www.w3.org/1999/xhtml">
        <head id="Head">
        <title></title>

        <link href="bootstrap.css" rel="stylesheet" />
        <link href="bootstrap.min.css" rel="stylesheet" />
        </head>

        <body>
        <form id="form2">
            <div
            // style={{
            //     display: "block",
            //     margin: "auto",
            //     maxWidth: "300px",
            //     transform: "translate(10%, -50%)",
            // }}
            // className="col-md-4 col-lg-2"
            >
                <br />
            {!isDamageRecorded && (
                <input
                id="btnSubmitPOI"
                type="button"
                // className="button"
                style={{
                    outline: "none",
                    display: "block",
                    padding: "1vw", // Adjust padding to be responsive
                    // minWidth: "200px", // Minimum width to ensure legibility
                    width: "45vw",
                    maxWidth: "20rem",
                    margin: "20px auto",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    fontSize: "min(1.5vw, 16px)", // Adjust font size to be responsive
                    cursor: "pointer",
                    backgroundColor: '#21af97',
                }}
                value="Save Car Damaged Parts"
                onClick={saveStateAsJSON}
                />
            )}
<Dialog
    open={showSuccessModal}
    onClose={handleCloseModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
>
    <DialogTitle id="alert-dialog-title">{"Damage Saved Successfully"}</DialogTitle>
    <DialogContent>
        <DialogContentText id="alert-dialog-description">
            Please confirm whether you would like to go back?
        </DialogContentText>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleGoBack} color="primary">
            Yes
        </Button>
        <Button onClick={handleCloseModal} color="primary" autoFocus>
            No
        </Button>
    </DialogActions>
</Dialog>

 {/* Warning modal */}
 <Modal
                open={showWarningModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Stack
                    sx={{
                        position: 'fixed',
                        top: '10%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80vw',
                        maxWidth: '500px',
                        zIndex: '9999',
                        textAlign: 'left' 
                    }}
                    spacing={2}
                >
                    <Alert severity="warning" onClose={handleCloseModal}>
                        <AlertTitle>Sorry, it seems like you have not selected any damage.</AlertTitle>
                        
                    </Alert>
                </Stack>
            </Modal>


            </div>
            <div
            // style={{
            //     display: "block",
            //     margin: "auto",
            //     maxWidth: "300px",
            //     transform: "translate(10%, -50%)",
            // }}
            // className="col-md-4 col-lg-2"
            >
            <input
                id="btnClaimForm"
                type="button"
                // className="button"
                style={{
                    outline: "none",
                    display: "block",
                    padding: "1vw", // Adjust padding to be responsive
                    // minWidth: "200px", // Minimum width to ensure legibility
                    width: "45vw",
                    maxWidth: "20rem",
                    margin: "20px auto",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    fontSize: "min(1.5vw, 16px)", // Adjust font size to be responsive
                    cursor: "pointer",
                    backgroundColor: '#ec6466',
                }}
                value="Back to Claim Form"
                onClick={btnBackClaimForm_Click}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        It seems you have not saved your damage. Do you still want to go back?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleGoBack} color="primary">
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
            </div>
            <div
            // style={{
            //     display: "block",
            //     margin: "auto",
            //     transform: "translate(35%, -80%)",
            // }}
            >
            {/* <input
                id="lblmssg"
                type="text"
                visible="true"
                style={{
                color: "blue",
                fontSize: "large",
                border: "hidden",
                fontWeight: "100",
                width: "100%",
                height: "25px",
                }}
            /> */}
            </div>
        </form>
        </body>
    </html>
    </div>
</div>
);
}

export default DamageSelection;
