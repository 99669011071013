import React from "react";
import rotate_phone from "../Assets/Images/rotate-phone-peach.gif";

const Landscape = () => {
  return (
    <div className="warning_message">

      <img className="rotate_screen" src={rotate_phone} alt="rotate-phone" />
      <div>
        <p className="rotate_txt" style={{ color: "#000" }}>
          Please Rotate Your Phone
        </p>
        <p className="rotate_txt" style={{ color: "#ec6466" }}>
          To Start Inspection
        </p>
      </div>
    </div>
  );
};

export default Landscape;
