import { createSlice } from '@reduxjs/toolkit'

export const tncSlice = createSlice({
  name: 'tnc',
  initialState:false,
  reducers: {
    updateTnC: (state) => true,
    
  },
})

export const { updateTnC } = tncSlice.actions

export default tncSlice.reducer