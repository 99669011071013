import React,{useEffect} from 'react';
// import * as tf from "@tensorflow/tfjs";
import { Routes, Route } from "react-router-dom";
import Home from './Pages/Home'
import Inspection from './Pages/Inspection'
import Upload from './Pages/Upload'
import './index.scss';
// import { setModelRedux } from "../src/Redux/Features/modelSlice";
import TermsCondition from './Pages/Terms_Condition';
// import { useDispatch,useSelector } from 'react-redux';
import Thank from './Pages/Thank';
import UploadError from './Pages/UploadError';
import DamageSelection from './Pages/DamageSelection';
import UploadCDF from './Pages/Upload-CDF';

const App=()=> {
  // console.log("window.navigator.standalone",window.navigator.standalone)
  // console.log("(window.matchMedia('(display-mode: standalone)').matches)",(window.matchMedia('(display-mode: standalone)').matches))
  // const dispatch = useDispatch();
  // const modelWrapper=useSelector((state)=>state.model)

  //  function loadModel() {
  //   try {
  //     console.time("model load")
  //       tf.loadGraphModel(process.env.PUBLIC_URL+ "/web_model/model.json")
  //      .then((model)=>{
  //       console.timeEnd("model load")
  //       console.time("sdkmodelloadWarmpup")        
  //       dispatch(setModelRedux(model))
  //       console.timeEnd("sdkmodelloadWarmpup")
  //      })
  //     // setLoading(false)
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

//   const modelWarmup=async()=>{
//     let [modelWidth, modelHeight] = modelWrapper.model.inputs[0].shape.slice(1, 3);
// // const imgElem = document.createElement("img");
// // imgElem.src = inputShape;
// // imgElem.width = 1024;
// // imgElem.height = 768;

// // imgElem.crossOrigin = "*";

// // imgElem.onload = async function () {
// //   console.log("this",this)
// //   let c = document.createElement("canvas");
// //   const ctx = c.getContext("2d");
// //   ctx.canvas.width = this.width;
// //   ctx.canvas.height = this.height;
// //   ctx.drawImage(this, 0, 0);

// //     const input = tf.tidy(() => {
// //       return tf.image
// //         .resizeBilinear(tf.browser.fromPixels(c), [modelWidth, modelHeight])
// //         .div(255.0)
// //         .expandDims(0);
// //     });
// //     // await modelWrapper.model.executeAsync(tf.zeros([1,640,640,3])).then((res) => {
// //     //   res[0].dataSync(); // we don't care about the result
// //     //   tf.dispose(res);
// //     //   console.log("model loaded")
// //     // });
// // };
// console.time("warmup")
//  modelWrapper.model.executeAsync(tf.zeros([1,640,640,3])).then((res) => {
//   // we don't care about the result
//       tf.dispose(res);
//       console.log("model loaded")
//     });
//     console.timeEnd("warmup")

//   }

  //  useEffect(() => {
  //   // Warmup the model before using real data.
  //   console.log("warmpup")
  //   console.log("JSON.strignfig",modelWrapper.model)
  //   if (modelWrapper.model) {
  //    modelWarmup()
  //   }
  // }, [modelWrapper.model]);

 
  
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms&conditions" element={<TermsCondition />} />
        <Route path="/inspection" element={<Inspection />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/thank" element={<Thank />} />
        <Route path="/upload-error" element={<UploadError />} />
        <Route path="/damage-selection" element={<DamageSelection />} />
        <Route path="/upload-cdf" element={<UploadCDF />} />
      </Routes>
  );
}

export default App;
