import React, { useState, useRef,useEffect } from "react";
import Fullscreen from "../Assets/Images/fullscreen.svg";
import ExitScreen from "../Assets/Images/exit-screen.svg"
import Camera from "../Assets/Images/camera.svg";
import Lightmode from "../Assets/Images/light-mode.svg";
import DarkMode from "../Assets/Images/dark-mode.svg";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../Redux/Features/themeSlice";

const RightContent = ({ mainContainerRef, capture }) => {
  const fullScreenRef = useRef(null);
 const [elem,setElem]=useState(mainContainerRef.current)
  useEffect(() => {
    if(mainContainerRef.current)
     setElem(mainContainerRef.current);
  }, []);
  const dispatch = useDispatch();

const [fullScreen,setFullScreen]=useState(false)
  const theme = useSelector((state) => state.theme);

  const openFullScreen = () => {
    //console.log("elem",elem)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    setFullScreen(true)
  };

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
    setFullScreen(false)
  };

  return (
    <div
      className="settings"
      style={{ backgroundColor: theme === "dark" ? "#242424" : "#ffffff" }}
    >
    <img
        ref={fullScreenRef}
        className="full_screen"
        style={{visibility:iOS()?'hidden':'visible'}}
        onClick={(e)=>{
          e.preventDefault();
          !fullScreen?openFullScreen():exitFullScreen()}}
        src={!fullScreen?Fullscreen:ExitScreen}
        alt="fullscreen-mode"
      /> 
   
      <img
         onClick={capture}
        className="camera_icon"
        id="takePhotoButton"
        src={Camera}
        alt="click"
      />
      <img
        className="theme_mode"
        onClick={(e) => {
          e.preventDefault();
          theme === "dark"
            ? dispatch(setTheme("light"))
            : dispatch(setTheme("dark"));
        }}
        src={theme === "dark" ? Lightmode : DarkMode}
        alt="select-theme"
      />
    </div>
  );
};

export default RightContent;
