import { configureStore  } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import themeReducer from './Features/themeSlice'
import carReducer from './Features/carSlice'
import tncReducer from './Features/tnCSlice'
import modelReducer from './Features/modelSlice'
import breakinReducer from './Features/breakinSlice'
import isClaimReducer from './Features/isClaimSlice'
import locationReducer from './Features/locationSlice'
import damageReducer from './Features/damageSlice'

import {
  persistReducer,
} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";


const reducers = combineReducers({
  theme: themeReducer,
  car:carReducer,
  tnc:tncReducer,
  model:modelReducer,
  breakin:breakinReducer,
  isClaim:isClaimReducer,
  location: locationReducer,
  damage: damageReducer,
});

const persistConfig = {
  key: "user",
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>    getDefaultMiddleware({      serializableCheck: false    }),
});
