import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { damage } from "../Redux/Features/damageSlice";
import { useNavigate } from "react-router-dom";
import CarCrashTwoToneIcon from "@mui/icons-material/CarCrashTwoTone";
import {
    Grid,
    Card,
    CardContent,
    Button,
    FormControl,
    FormGroup,
    TextField,
    Typography,
    Radio,
} from "@mui/material";

const SubmitForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        isParked: false,
        inWorkshop: false,
        licenseNumber: "",
        accidentDescription: "",
        charCountWarning: false,
        isFormValid: false,
        licenseNumberRequired: true,
    });

    const breakin = useSelector((state) => state.breakin);
    const damageData = useSelector((state) => state.damage.damageData);
    const isDamageRecorded = useSelector(
        (state) => state.damage.isDamageRecorded
    );
    const reduxDamage = useSelector((state) => state.damage.damageData);

    const [charCountWarn, setCharCountWarn] = useState(false);
    let fetchedDamageData = null;
    useEffect(() => {
        const checkLocalStorage = () => {
            const localDamageData = localStorage.getItem("lc_damage");
            // console.log(localDamageData);
            if (!!localDamageData) {
                dispatch(damage.setDamageRecorded("true"));
                dispatch(damage.setDamageData(localDamageData));
            }
        };
        const interval = setInterval(checkLocalStorage, 3000);
        return () => clearInterval(interval);
    }, [damageData]);

    useEffect(() => {
        const isLicenseNumberRequired = formData.isParked === false;
        // console.log("Using Effect.");
        const isFormValid =
            isDamageRecorded &&
            charCountWarn &&
            formData.accidentDescription !== "" &&
            (!isLicenseNumberRequired || formData.licenseNumber.trim().length > 0);

        setFormData((prevFormData) => ({
            ...prevFormData,
            isFormValid: isFormValid,
            licenseNumberRequired: isLicenseNumberRequired,
        }));
    }, [
        formData.isParked,
        formData.inWorkshop,
        formData.licenseNumber,
        formData.accidentDescription,
        isDamageRecorded,
    ]);

    useEffect(() => {
        fetchedDamageData = fetchDamageFromDB();

        fetchedDamageData
            .then((response) => {
                if (response !== null && Object.keys(response).length > 0) {
                    // console.log(response);
                    dispatch(damage.setDamageRecorded(true));
                    dispatch(damage.setDamageData(response));
                    // console.log(fetchedDamageData);
                } else {
                    // console.log("No damage selected.");
                }
            })
            .catch((error) => {
                console.error("An error occurred while fetching damage data:", error);
            });
    }, []);

    async function fetchDamageFromDB() {
        try {
            const encClaimNo = encodeData(breakin.claim_number);

            let data = JSON.stringify({
                ClaimNo: encClaimNo,
            });

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/fetchPOIDamage`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            // Check if the response contains an error message
            if (
                response.data?.GetDataForPOICustomerResult &&
                response.data.GetDataForPOICustomerResult.Message &&
                response.data.GetDataForPOICustomerResult.Message.Error
            ) {
                return null;
            }

            // Directly return the response data
            return response.data;
        } catch (error) {
            console.error(error);
            return null; // Return null in case of error
        }
    }

    // Button style
    const submitButtonStyle = {
        outline: "none",
        display: "block",
        padding: "12.5px",
        width: "100%",
        margin: "20px auto",
        backgroundColor: formData.isFormValid ? "#EC6466" : "grey",
        color: "white",
        border: "none",
        borderRadius: "8px",
        fontSize: "20px",
        cursor: "pointer",
    };

    const encodeData = (data) => {
        const text =
            "7E1sXThddD3DprfTJkbNY+szSTyPHOimYamZ6BSrmD45454545+s98bx3HSbVpCaZPVtlvNfj2GFI1oO6K9RK70KKhjRqf0oe";

        const md5Hash = CryptoJS.MD5(text);
        const key = CryptoJS.enc.Hex.parse(md5Hash.toString().slice(0, 32));

        const encrypted = CryptoJS.TripleDES.encrypt(data, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();

        // console.log(encrypted);
        return encrypted;
    };

    const handleDamageSelectionClick = () => {
        window.open("/damage-selection", "_blank");
    };

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;
        const charCountWarning =
            name === "accidentDescription" && value.length > 200;
        // console.log(charCountWarning);
        setCharCountWarn(!charCountWarning ? true : false);
        setFormData((prevValues) => ({
            ...prevValues,
            [name]: e.target.type === "checkbox" ? checked : value,
            charCountWarning: charCountWarning,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        // Prepare data for submission
        const data = {
            AccidentDate: breakin.loss_date,
            AccidentTime: breakin.loss_time,
            DescriptionOfAccident: formData.accidentDescription,
            DrivingLicenceNo: formData.licenseNumber,
            InsuredName: breakin.insured_name,
            MasterClaimNumber: breakin.claim_number,
            PointOfImpact: fetchedDamageData ? fetchedDamageData : JSON.parse(damageData),
            PolicyNo: breakin.pol_num,
            WorkshopCode: "",
            WorkshopName: "",
            IsVehicleParked: formData.isParked,
            IsVehicleAtWorkshop: formData.inWorkshop,
        };
        // console.log(data);
        const encryptedData = encodeData(JSON.stringify(data)); // Assuming encodeData is defined elsewhere

        const url = `${process.env.REACT_APP_API_URL}/saveClaimForm`;
        const config = {
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_STATIC_API_TOKEN}`,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await axios.post(
                url,
                { EncJSON: encryptedData },
                config
            );
            // console.log(response.data);
            navigate("/thank");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Card
            sx={{
                margin: "5px",
                boxShadow: "0px 2px 6px rgba(0,0,0.1)",
                borderRadius: "7px",
                mt: "15px",
            }}
            >
            <CardContent>
                <Typography
                variant="h5"
                gutterBottom
                sx={{ fontSize: "20px", fontWeight: "bold" }}
                >
                Digital Claim Form
                </Typography>
                <Typography variant="body2" color="textSecondary">
                Please provide with the following information to start your claim
                journey.
                </Typography>
                <br />
                <FormControl component="fieldset">
                <FormGroup>
                    <Grid container spacing={2}>
                    {/* Damage Selection */}
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={6}>
                        <Typography variant="body1" color="textPrimary">
                            Please select the damages to your vehicle on the image
                        </Typography>
                        </Grid>
                        <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        >
                        <Button
                            variant="contained"
                            startIcon={
                            <CarCrashTwoToneIcon style={{ marginLeft: "10px" }} />
                            }
                            sx={{
                            borderRadius: "10px",
                            bgcolor: "#ec6466",
                            color: "white",
                            width: { xs: "100%", md: "auto" },
                            }}
                            onClick={handleDamageSelectionClick}
                        >
                            Tap here to select your Damage.
                        </Button>
                        </Grid>
                    </Grid>

                    {/* Accident Description */}
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary">
                        Please describe the accident in detail:
                        </Typography>
                        <TextField
                        label={
                            formData.accidentDescription === ""
                            ? ""
                            : `${Math.abs(
                                Math.max(
                                    -200,
                                    200 - formData.accidentDescription.length
                                )
                                )} ${
                                formData.accidentDescription.length > 200
                                    ? "characters extra"
                                    : "characters remaining"
                                }`
                        }
                        multiline
                        error={
                            formData.charCountWarning ||
                            formData.accidentDescription === ""
                        }
                        helperText={
                            formData.charCountWarning
                            ? "Maximum character limit exceeded."
                            : formData.accidentDescription === ""
                            ? "This field is required"
                            : "200 characters max."
                        }
                        rows={4}
                        fullWidth
                        margin="normal"
                        name="accidentDescription"
                        value={formData.accidentDescription}
                        onChange={handleInputChange}
                        placeholder="In English - max 200 characters."
                        />
                    </Grid>

                    {/* Radio Buttons Grid */}
                    <Grid item xs={12} container alignItems="center">
                        {/* Text */}
                        <Grid item xs={12} md={6}>
                        <Typography variant="body1" color="textPrimary">
                            Was your vehicle parked at the time of accident?
                        </Typography>
                        </Grid>
                        {/* Yes */}
                        <Grid item container alignItems="center" xs={6} md={2}>
                        <Radio
                            color="secondary"
                            checked={formData.isParked}
                            onChange={() =>
                            handleInputChange({
                                target: { name: "isParked", value: true },
                            })
                            }
                        />
                        <Typography variant="body2" color="textPrimary">
                            Yes
                        </Typography>
                        </Grid>
                        {/* No */}
                        <Grid item container alignItems="center" xs={6} md={2}>
                        <Radio
                            color="secondary"
                            checked={!formData.isParked}
                            onChange={() =>
                            handleInputChange({
                                target: { name: "isParked", value: false },
                            })
                            }
                        />
                        <Typography variant="body2" color="textPrimary">
                            No
                        </Typography>
                        </Grid>
                    </Grid>

                    {/* Driving License Number */}
                    <Grid item xs={12}>
                        <Typography variant="body1" color="textPrimary">
                        Driving Licence No.
                        </Typography>
                        <TextField
                        // label={formData.licenseNumberRequired ? 'Driving License Number *' : ''}
                        fullWidth
                        margin="normal"
                        error={
                            formData.licenseNumberRequired &&
                            formData.licenseNumber === ""
                        }
                        helperText={
                            formData.licenseNumberRequired &&
                            formData.licenseNumber === ""
                            ? "This field is required."
                            : ""
                        }
                        name="licenseNumber"
                        value={formData.licenseNumber}
                        onChange={handleInputChange}
                        required={formData.licenseNumberRequired}
                        placeholder="Please include dash, slash as per your original document."
                        />
                    </Grid>
                    </Grid>
                </FormGroup>
                <Typography variant="body1" fontWeight="bold">
                    Workshop Name:{" "}
                    <span style={{ color: "#ec6466", fontStyle: "italic" }}>
                    {breakin.workshop_name
                        ? breakin.workshop_name
                        : "Not in workshop"}
                    </span>
                </Typography>
                </FormControl>
            </CardContent>
            {/* <CardActions style={{ justifyContent: 'center' }}>
                    <Button variant="contained" sx={{ borderRadius: '20px', bgcolor: '#ec6466', color: 'white' }} onClick={handleDamageSelectionClick}>
                        Damage Selection
                    </Button>
                </CardActions> */}
            </Card>
            <Button
            //type="submit"
            style={submitButtonStyle}
            disabled={!formData.isFormValid}
            onClick={handleSubmit}
            >
            Submit
            </Button>
        </>
    );
};

export default SubmitForm;
