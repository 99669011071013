import React, { useEffect } from 'react'
import Logo from '../Assets/Images/logo_hdfc.png'
import { useSelector, useDispatch } from "react-redux";
import { setSendSMS } from "../Redux/Features/carSlice";


const Thank = () => {
  const breakin = useSelector((state) => state.breakin);
  //console.log("breakin", breakin.MOBILE_NUMBER)
  const dispatch = useDispatch();
  const car = useSelector((state) => state.car);
  const isClaim = useSelector((state) => state.isClaim);

  useEffect(() => {
    if (!car.sms && !isClaim) {
      setTimeout(() => {
        dispatch(setSendSMS());
        window.open(`https://api.whatsapp.com/send?phone=918169500500`);
        // window.open(`https://wa.me/918169500500`);
      }, 2000)
    }
  }, [])

  if (isClaim) {
    return (
      <section className="success_container">
          <img src={Logo} alt="hdfc-ergo" />
          <p id="thankyou_txt">Thank You, your claim is submitted for processing.</p>
          {/* <p id="success_para">You have successfully submitted the required photographs. We will review and get back to
              you within 1 hour.
          </p> */}
      </section>
    )
  }
  else {
    return (
      <section className="success_container">
        <img src={Logo} alt="hdfc-ergo" />
        <p id="thankyou_txt">Thank You.</p>
        <p id="success_para">You have successfully submitted the required photographs. We will review and get back to
          you within 1 hour.
        </p>
      </section>
    )
  }
}

export default Thank