import { createSlice } from '@reduxjs/toolkit'

export const modelSlice = createSlice({
  name: 'tnc',
  initialState:{model:false,loading:true,validation:[]},
  reducers: {
    setModelRedux(state,action){
    return {...state,model:action.payload,loading:false}
    },
    // setLoading(state){
    //   return {...state,loading:!state.loading}
    // }
    setValidation:(state,action)=>{
      const validation=JSON.parse(JSON.stringify(state.validation));
      const tempArray=[...validation]
      let foundIndex = state.validation.findIndex(e => (e.key===action.payload.key));
    if (foundIndex>-1) {
        tempArray[foundIndex].result = action.payload.result;
        tempArray[foundIndex].key = action.payload.key;
    } else {
        tempArray.push(action.payload);
    }
      return{
        ...state,
        validation:tempArray
      }
    },
    setUploaded:(state,action)=>{
      return {...state,validation:action.payload}
    }
  },
})

export const { setModelRedux,setLoading,setValidation,setUploaded } = modelSlice.actions

export default modelSlice.reducer