import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
    name: "location",
    initialState: {
        lat: null,
        lon: null,
    },
    reducers:{
    setLatitude: (state, action) => {
        state.lat = action.payload;
    },
    setLongitude: (state, action) => {
        state.lon = action.payload;
    },
    },
});

export const { setLatitude, setLongitude } = locationSlice.actions;

export default locationSlice.reducer;