import React,{useState,useEffect} from 'react'
import { useNavigate, useLocation } from "react-router-dom";

const Loader = () => {
  // const [wifispeed, setwifiSpeed] = useState("Checking speed...");
  

  const querySearch = useLocation().search;

  // const breakinID = new URLSearchParams(querySearch).get("breakinID");
  const claimNumber = new URLSearchParams(querySearch).get("claimNumber");

  const isClaim = !!claimNumber;

  const messages = isClaim
    ? ["Loading. \nPlease wait...", "Please wait! We are fetching your Claim details.", "Oops! Seems we are taking some more time", "Putting all details together now. One moment please!"]
    : ["Loading. \nPlease wait...", "Please wait! We are fetching your breakin details.", "Oops! Seems we are taking some more time", "Putting all details together now. One moment please!"];

  const [message, setMessage] = useState(messages[0]);


useEffect(() => {
messages.map((e,i)=>{
  setTimeout(()=>{
    setMessage(e)
  },i*3000)

})

}, [])


  return (<>
      {/* <img className='loaderGIF' src={LoaderGIF} alt="Loading" /> */}
    <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',height:'100vh',margin:'0 20px'}} >
    <div className='loader'></div>
    <div className='text' style={{marginTop:'40px'}}>{message} </div>
    </div>
    
  </>
  )
}

export default Loader