/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as tf from "@tensorflow/tfjs";
import RightContent from "./RightContent";
import LeftContent from "./LeftContent";
import Camera from "./Camera";
import { carCount, setCars } from "../Redux/Features/carSlice";
import { setValidation } from "../Redux/Features/modelSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useDidMountEffect from "../CustomHooks/useDidMountEffect";
import axios from "axios";
import { setLoading } from "../Redux/Features/modelSlice";
import Loader from "../Components/Loader";
import Modal from "../Components/Modal";
import Landscape from "./Landscape";
// import { setLatitude, setLongitude } from "../Redux/Features/locationSlice";

const Portrait = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let intervalId; // Declare intervalId outside useEffect
  const [loading, setLoading] = useState(false);

  const [retakeValidation, setRetakeValidation] = useState(false);
  const [cameraBlocked, setCameraBlocked] = useState(undefined);
  const [latitude, setLatitude] = useState("19.1624");
  const [longitude, setLongitude] = useState("72.9376");

  //console.log("cameraBlocked", cameraBlocked);

  const isClaim = useSelector((state) => state.isClaim);

  const car = useSelector((state) => state.car);
  // const validate = car.data.find((e) => Number(e.key) === Number(car.count));
  // console.log("validate",validate)

  const modelWrapper = useSelector((state) => state.model);
  // console.log("modelWrapper",modelWrapper.validation)
  const breakin = useSelector((state) => state.breakin);

  const webcamRef = useRef(null);
  const mainContainerRef = useRef(null);

  const isEveryUpload = car.data.every((item) => item.isUpload);
  const isDetected = modelWrapper.validation.length === 8;

  const model = modelWrapper.model;

  const [result, setResult] = useState({});
  const [imgObj, setImgObj] = useState(null);
  const [carDetected, setCarDetected] = useState({
    msg: "Searching For Car",
    value: "S",
  });

  const [locationBlocked, setLocationBlocked] = useState(false);

  // const [captureBtn,setCaptureBtn]=useState(false)

  async function compressImage(imgToCompress) {
    // resizing the image
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const imgElem = document.createElement("img");
    imgElem.src = imgToCompress;
    imgElem.width = 1920;
    imgElem.height = 1080;

    canvas.width = 1920;
    canvas.height = 1080;
    const url = (imgElem.onload = async function () {
      await context.drawImage(imgElem, 0, 0, 1920, 1080);
      const url = await canvas.toDataURL("image/jpeg", 0.7);

      return url;
    });

    return url;
  }
  // useDidMountEffect

  function r(e, t) {
    var i = parseInt(e),
      n = parseInt(t);
    return (
      (isNaN(i) || isNaN(n) ? " " : ((n / i) * 100).toFixed(3)) / 100
    ).toFixed(2);
  }
  async function predict(img, key) {
    let t = document.createElement("canvas");
    let n = img.width;
    let a = img.height;
    const m = t.getContext("2d");
    m.canvas.width = img.width;
    m.canvas.height = img.height;
    m.drawImage(img, 0, 0);
    function getCanvasBlob(mycanvas) {
      return new Promise(function (resolve) {
        mycanvas.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      });
    }
    let myblob = await getCanvasBlob(t);
    let result = await andy(myblob, n, a);
    //console.log("result after clicked", result);
    dispatch(setValidation({ result, key }));
    // if(car.retake) setRetakeValidation(true)
    // let tempData = JSON.parse(JSON.stringify(car.data));
    // const detection = result.car;
    // const carInFrame = result.car_in_frame;
    // const d_precision = result.d_precision;
    // const h_precision = result.h_precision;
    // const v_precision = result.v_precision;
    // const final_side = result.final_side;
    // var newStr = tempData[key-1].imgId.replace(/_/g, "");

    // const isCarValue = detection
    //   ? newStr !== final_side
    //     ? "Wrong Angle"
    //     : carInFrame
    //     ? "Car Detected"
    //     : d_precision <= -2
    //     ? "Too Far"
    //     : d_precision >= 2
    //     ? "Too Close"
    //     : h_precision <= -2
    //     ? "Too Left"
    //     : h_precision >= -2
    //     ? "Too Right"
    //     : v_precision <= -2
    //     ? "Too Up"
    //     : v_precision >= -2 && "Too Down"
    //   : "Car Not Detected";
    // console.log("key",key)
    // const validation=await JSON.parse(JSON.stringify(modelWrapper.validation));
    // console.log("validation",validation)
    // const tempArray=[...validation]
    //   const data={
    //     key,
    //     isCar:result.car && result.car_in_frame && newStr === final_side,
    //     message:isCarValue
    //   }
    //   // tempData[foundIndex].isCar =
    //   // result.car && result.car_in_frame && newStr === final_side;
    //   // tempData[foundIndex].message = isCarValue;
    //   let foundIndex = validation.findIndex(e => (e.key===car.count));
    // if (foundIndex>-1) {
    //     tempArray[foundIndex].isCar = data.isCar;
    //     tempArray[foundIndex].message = data.message;
    // } else {
    //     tempArray.push(data);
    // }
    // if(car.retake) setRetakeValidation(true)
    //   dispatch(setValidation(tempArray))
  }

  function getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  function base64ToBlob(base64Image) {
    const parts = base64Image.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const base64String = parts[1];

    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);

    return new Blob([byteArray], { type: contentType });
  }

  async function andy(e, b, a) {
    let result;
    let x2;
    let y1;
    let y2;
    let score;
    let width;
    let height;
    let t = new FormData();
    //console.log("Image value of n", b);
    // let currentBuffer=Buffer.from(e);
    // blobToBase64(e)
    //   .then(base64Image => {
    //     console.log(base64Image);
    //   })
    //   .catch(error => {
    //     console.error(error);
    //   });
    t.append("image", e),
      t.append("ref_num", "666"),
      t.append("source_name", "hdfcergo");
    let config = {
      method: "post",
      url: "https://aicamera.camcom.ai/roi-capture",
      data: t,
    };
    await axios(config)
      .then(function (e) {
        let o = (x2 = y1 = y2 = score = width = height = 0);
        // d = "-";\
        if (e?.data?.message) {
          result = { car: false, message: e.data.message, isapimsg: true };
        } else if (
          e.data.hasOwnProperty("car_coords") &&
          e.data.hasOwnProperty("conf_score") &&
          e.data.hasOwnProperty("final_side")
        ) {
          try {
            if (e.data.conf_score) {
              e.data.conf_score = e.data.conf_score * 100;
            }
          } catch (e) {}
          let i = e.data.car_coords;

          let o = i[0];

          let y1 = i[1];

          let x2 = i[2];

          let y2 = i[3];

          let score = e.data.conf_score;

          let width = x2 - o;

          let height = y2 - y1;

          // console.log({ width });
          //console.log({ height });

          function Details(e, t, i, n, a, o, c, d) {
            let p = (c - a) / 2;
            let s = (d - o) / 2;
            let h = 0;
            let v_p = 0;
            let d_p = 0;
            let m = !0;
            let tvv;
            let vmp;
            let diff;
            e < 0 && ((e = 0), (m = !1));
            t < 0 && ((t = 0), (m = !1));
            i > c && ((i = width), (m = !1));
            n > d && ((n = height), (m = !1));
            var f = r(p, e),
              l = (r(p, c - i), r(s, t), r(s, d - n), (tvv = 0)),
              _ = (vmp = 1);
            e == p || (e > p ? (l = e - p) : ((l = p - e), (_ = -1)));
            t == s || (t > s ? (tvv = t - s) : ((tvv = s - t), (vmp = -1)));
            let u = r(c, l),
              g = r(d, tvv);
            (h = parseInt(10 * u) * _),
              (v_p = parseInt(10 * g) * vmp),
              (diff = 100 * r(c * d, a * o)),
              //console.log(diff);
              f < 0 && (f = 0);
            diff >= 98 && (d_p = 1);
            diff <= 40 && (d_p = -1);

            //console.log("pdiff", diff);

            return {
              h_precision: h,
              v_precision: v_p,
              d_precision: d_p,
              car_in_frame: m,
            };
          }
          let data =
            // (score>30 && score<70) &&
            score > 0 &&
            e.data.final_side &&
            Details(o, y1, x2, y2, width, height, b, a);

          let car = score > 0;

          //console.log("data", data);

          result = {
            ...data,
            o,
            y1,
            x2,
            y2,
            car,
            coord: e.data.car_coords,
            confidence: score,
            message: e.data.message,
            final_side: e.data.final_side,
          };
        } else {
          result = { car: false, message: e.message };
        }
      })
      .catch(function (error) {
        // console.log(error);
      });

    let tempData = JSON.parse(JSON.stringify(car.data));
    const detection = result.car;
    // const detection = Math.random() < 0.5;
    const carInFrame = result.car_in_frame;

    const d_precision = result.d_precision;
    const h_precision = result.h_precision;
    const v_precision = result.v_precision;
    const final_side = result.final_side;
    var newStr = tempData[car.count - 1].imgId.replace(/_/g, "");
    // const newStr=tempData[Math.floor(Math.random() * 11)].imgId.replace(/_/g, "")

    // console.log("detection", detection);
    if (result?.isapimsg == true) {
      setCarDetected({
        msg: result.message,
        value: "ND",
      });
    } else {
      setCarDetected({
        msg: detection
          ? result.confidence < 30
            ? "Car Not Detected"
            : newStr !== final_side
            ? "Wrong Angle"
            : result.confidence < 70
            ? !carInFrame
              ? `Car not in Frame`
              : d_precision < 0
              ? "Too far, please take a step forward."
              : d_precision > 0
              ? "Too close, please take a step back."
              : h_precision <= -2
              ? "Too Left, please move to right "
              : h_precision >= 2
              ? "Too Right, please move to left"
              : v_precision <= -2
              ? "Too Up, please hold down"
              : v_precision >= 2
              ? "Too Down, please hold up"
              : `Low Accuracy ${Math.round(result.confidence)}`
            : result.confidence > 70 &&
              `Car Detected ${final_side} with accuracy ${Math.round(
                result.confidence
              )} `
          : "Searching For Car",
        value: detection
          ? result.confidence < 30
            ? "ND"
            : result.confidence > 70 && newStr === final_side
            ? "D"
            : (newStr !== final_side ||
                !carInFrame ||
                (d_precision < 0 && d_precision > 0) ||
                (h_precision <= -2 && h_precision >= -2) ||
                (h_precision <= -2 && h_precision >= -2) ||
                result.confidence < 70) &&
              "M"
          : "S",
      });
    }
    // setCarDetected({msg:` Car Detected with accuracy ${result.confidence} ${result.final_side} `})
    //console.log("result of the following", result);
    setResult(result);
    return result;
  }

  useDidMountEffect(async () => {
    if (!cameraBlocked) {
      // console.log("car.count", car.count);
      if (car.count <= 8) {
        const imageSrc = webcamRef.current.getScreenshot();
        const blobImage = base64ToBlob(imageSrc, "image/jpeg");
        await andy(
          blobImage,
          webcamRef.current.videoWidth,
          webcamRef.current.videoHeight
        );
      }
      // else {
      //   setResult({})
      // }
    }
  }, [result]);
  useDidMountEffect(() => {
    if (isEveryUpload) {
      navigate("/upload");
      // if(car.retake && retakeValidation){
      //   setLoading(false)
      //   navigate("/upload");
      // }
      // else if(modelWrapper.validation.length===8){
      // setLoading(false)
      // navigate("/upload");
      // }
    }
    // }, [car,modelWrapper,retakeValidation]);
  }, [car]);

  let watchId;

  const capture = async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    // const imgCompress= await compressImage(imageSrc);
    let tempData = JSON.parse(JSON.stringify(car.data));
    const foundIndex = tempData.findIndex(
      (e) => Number(e.key) === Number(car.count)
    );
    // const { imgId, name, key } = tempData[foundIndex];
    if (foundIndex > -1) {
      tempData[foundIndex].isUploadImg = imageSrc;
      tempData[foundIndex].isUpload = true;

      tempData[foundIndex].latitude = latitude;
      tempData[foundIndex].longitude = longitude;

      if (tempData[foundIndex].toValidate) {
        const imgElem = document.createElement("img");
        imgElem.src = imageSrc;
        imgElem.width = 1024;
        imgElem.height = 768;
        imgElem.width = webcamRef.current.videoWidth;
        imgElem.height = webcamRef.current.videoHeight;
        imgElem.crossOrigin = "*";

        imgElem.onload = async function () {
          await predict(imgElem, car.count);
        };
      }

      if (!isEveryUpload) {
        dispatch(carCount());
      }

      setResult({});
      setCarDetected({
        msg: "Searching For Car",
        value: "S",
      });
      tempData[foundIndex].isActive = false;
      if (foundIndex < car.checkCount) {
        tempData[foundIndex + 1].isActive = true;
      }

      dispatch(setCars(tempData));
    }

    // uploadAIImage(imageSrc, imgId, name);
  };

  const startObjectDetection = () => {
    setCameraBlocked(false);
    webcamRef.current.video.onplaying = () => {
      let t = document.createElement("canvas");
      const video = webcamRef.current.video;
      const m = t.getContext("2d");

      (m.canvas.width = video.clientWidth),
        (m.canvas.height = video.clientHeight),
        m.drawImage(video, 0, 0),
        t.toBlob(andy, "image/jpeg");
    };
    //console.log("permission grnated");
  };

  const cameraBlock = (value) => {
    setCameraBlocked(value);
    // window.location.reload(false);
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // Attempt to get the current position
        watchId = navigator.geolocation.watchPosition(
          async (position) => {
            setLocationBlocked(false);
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          },
          async (error) => {
            console.error("Error getting current position:", error);
            try {
              const result = await navigator.permissions.query({
                name: "geolocation",
              });
              if (result.state === "granted") {
                setLocationBlocked(false);
                setLatitude("19.1624");
                setLongitude("72.9376");
              } else {
                setLocationBlocked(true);
              }
            } catch (error) {
              console.error("Error querying geolocation permission:", error);
            }
          },
          { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
        );
      } catch (error) {
        console.error("Error getting current position:", error);
      }
    };

    fetchLocation();

    // Clean up the watch when the component unmounts
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, []);

  return (
    <>
      <div ref={mainContainerRef} className="main_container">
        <div className="left_content">
          <LeftContent />
        </div>
        <div className="center_content">
          <Camera
            ref={webcamRef}
            detection={carDetected}
            result={result}
            startObjectDetection={startObjectDetection}
            cameraBlock={cameraBlock}
          />
        </div>
        <div className="right_content">
          <RightContent mainContainerRef={mainContainerRef} capture={capture} />
        </div>
      </div>
      {loading && (
        <Modal>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              margin: "0 20px",
            }}
          >
            <span
              className="please_wait"
              style={{ color: "white", fontSize: "20px" }}
            >
              Please wait...
            </span>
          </div>
        </Modal>
      )}

      {locationBlocked && (
        <Modal>
          <div className="modalContainer">
            <div className="retake_modal">
              <span>
                Please allow location access from your browser and refresh.
              </span>
              <div className="yes_no_btn">
                <button
                  style={{ backgroundColor: "#e42125" }}
                  onClick={() => window.location.reload()}
                >
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Portrait;
