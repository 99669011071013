import { createSlice } from '@reduxjs/toolkit';

const damageSlice = createSlice({
    name: 'damage',
    initialState: {
        isDamageRecorded: false,
        damageData: {}
    },
    reducers: {
        setDamageRecorded(state, action) {
        state.isDamageRecorded = action.payload;
        },
        setDamageData(state, action) {
        state.damageData = action.payload;
        }
    }
    });

export const damage  = damageSlice.actions;

export default damageSlice.reducer;
