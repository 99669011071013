import {React, useEffect} from 'react';
import { useSelector } from 'react-redux';
import DamageForm from '../Components/DamageForm'; // Import your DamageForm component
import { Card, CardContent, Typography } from '@mui/material';
// import { useEffect } from 'react';

const UploadCDF = () => {

    const breakin = useSelector((state) => state.breakin);
    useEffect(() => {
        document.title = "Claim Damage Form";
    }, []);

    return (
    <div>   
        <Card sx={{margin: '5px', boxShadow: '0px 2px 6px rgba(0,0,0.1)', borderRadius: '7px', mt: '15px' }}>
        <CardContent sx={{ paddingBottom: '2px', '&:last-child': { paddingBottom: '10px' } }}>
            <Typography variant="h5" gutterBottom sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Inspection photos have already been uploaded to the application.
            </Typography>
            {/* <Typography variant="body2" color="textSecondary">
            Please complete the form below for further claim processing.
            </Typography> */}
        </CardContent>
        </Card>
    <DamageForm />
    </div> 
    );
};

export default UploadCDF;
