import { createSlice } from '@reduxjs/toolkit'

export const breakinSlice = createSlice({
  name: 'breakin',
  initialState:false,
  reducers: {
    setBreakin: (state,action) => action.payload
  },
})

export const { setBreakin } = breakinSlice.actions

export default breakinSlice.reducer