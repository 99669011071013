import { createSlice } from "@reduxjs/toolkit";

export const isClaimSlice = createSlice({
    name: "isClaim",
    initialState: false,
    reducers: {
    setIsClaim: (state, action) => action.payload,
    },
});

export const { setIsClaim } = isClaimSlice.actions;

export default isClaimSlice.reducer;
